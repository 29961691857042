import React, { memo } from 'react'
import { AxisLeft, AxisBottom } from '@visx/axis'
import { theme } from 'twin.macro'

function OverviewAxes({
  xScale,
  yScale,
  top,
  leftHideZero,
  leftNumTicks,
  leftTickFormat,
  bottomTickFormat,
}) {
  return (
    <>
      <AxisLeft
        hideZero={leftHideZero}
        numTicks={leftNumTicks}
        scale={yScale}
        stroke={theme`colors.grey.5`}
        tickLabelProps={() => ({
          fill: theme`colors.grey.2`,
          fontSize: 12,
          textAnchor: 'end',
          dy: '0.33em',
          dx: '-0.6em',
        })}
        tickStroke={theme`colors.grey.5`}
        tickFormat={leftTickFormat}
      />
      <AxisBottom
        hideZero
        top={top}
        scale={xScale}
        stroke={theme`colors.grey.4`}
        tickFormat={bottomTickFormat}
        tickLabelProps={() => ({
          fill: theme`colors.grey.2`,
          fontSize: 12,
          textAnchor: 'middle',
        })}
        tickStroke={theme`colors.grey.5`}
      />
    </>
  )
}

export default memo(OverviewAxes)
