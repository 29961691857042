import React, { useCallback } from 'react'
import { GridColumns } from '@visx/grid'
import { curveLinear } from '@visx/curve'
import { Group } from '@visx/group'
import { scaleLinear, scalePoint } from '@visx/scale'
import { LinePath } from '@visx/shape'
import { max } from 'd3-array'
import { format } from 'd3-format'
import { theme } from 'twin.macro'

import OverviewAxes from '../OverviewAxes'
import OverviewTooltipContent from '../OverviewTooltipContent'
import OverviewChartTitle from '../OverviewChartTitle'
import useSVGTooltip from '../../connectors/useSvgTooltip'

const CIRCLE_RADIUS = 7
const margin = {
  top: 20,
  left: 70,
  right: 30,
  bottom: 20,
}

const getX = (d) => d.name
const getY = (d) => d.size

const formatX = (x) => {
  const [, quarter, year] = x.match(/(Q\d)\/(\d{4})/)
  return quarter === 'Q1' ? year : quarter
}

export default function TrendsChart({ width, height, data, unit, ...rest }) {
  const xMax = width - margin.left - margin.right
  const yMax = height - margin.top - margin.bottom
  const xScale = scalePoint({
    domain: data.map(getX),
    range: [0, xMax],
  })

  const yScale = scaleLinear({
    domain: [0, max(data, getY)],
    range: [yMax, 0],
    nice: true,
  })

  const style = { width }

  const {
    containerRef,
    handlePointer,
    TooltipInPortal,
    hideTooltip,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    tooltipData,
    tooltipStyles,
  } = useSVGTooltip()

  const handleTooltip = useCallback(
    (e, d) => {
      const value = format('.2f')(getY(d))
      handlePointer(e, {
        label: getX(d),
        value,
        unit,
      })
    },
    [handlePointer, unit]
  )

  return (
    <div style={style} tw="relative" {...rest}>
      <svg width={width} height={height} ref={containerRef}>
        <Group top={margin.top} left={margin.left}>
          <GridColumns
            scale={xScale}
            width={xMax}
            height={yMax}
            tw="fill-current text-grey-5"
          />
          <OverviewAxes
            xScale={xScale}
            yScale={yScale}
            top={yMax}
            leftNumTicks={yScale.ticks().length / 2}
            bottomTickFormat={formatX}
          />
          <LinePath
            curve={curveLinear}
            data={data}
            x={(d) => xScale(getX(d)) ?? 0}
            y={(d) => yScale(getY(d)) ?? 0}
            shapeRendering="geometricPrecision"
            tw="stroke-current text-blue"
          />
          {data.map((d, i) => (
            <circle
              key={`trend-point-${i}`}
              stroke="white"
              r={4}
              cx={xScale(getX(d))}
              cy={yScale(getY(d))}
              tw="text-blue fill-current stroke-2"
              onPointerEnter={(e) => handleTooltip(e, d)}
              onPointerLeave={hideTooltip}
            />
          ))}

          <OverviewChartTitle height={yMax} unit={unit} />
        </Group>

        {tooltipOpen && (
          <>
            <TooltipInPortal
              left={tooltipLeft}
              top={tooltipTop}
              style={tooltipStyles}
            >
              <OverviewTooltipContent tooltipData={tooltipData} />
            </TooltipInPortal>
          </>
        )}
      </svg>

      <div tw="mt-4 flex justify-center items-center">
        <svg width={CIRCLE_RADIUS * 2} height={CIRCLE_RADIUS * 2}>
          <circle
            fill={theme`colors.blue.DEFAULT`}
            r={CIRCLE_RADIUS}
            cx={CIRCLE_RADIUS}
            cy={CIRCLE_RADIUS}
          />
        </svg>
        <span tw="pl-2 text-xs">Emissions</span>
      </div>
    </div>
  )
}
