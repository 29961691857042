import React, { useState } from 'react'
import Pie, { ProvidedProps, PieArcDatum } from '@visx/shape/lib/shapes/Pie'
import { scaleOrdinal } from '@visx/scale'
import { LegendOrdinal, LegendItem, LegendLabel } from '@visx/legend'
import { Group } from '@visx/group'
import { animated, useTransition, to, SpringValue } from 'react-spring'
import tw, { theme } from 'twin.macro'

import { capitalize } from '../util'

interface PieData {
  name: string
  size: number
}

// accessor functions
const getValue = (d: PieData) => d.size

const defaultMargin = { top: 0, right: 0, bottom: 0, left: 0 }

export type PieProps = {
  width: number
  height: number
  margin?: typeof defaultMargin
  animate?: boolean
  pieData: PieData[]
  rest?: any
}

export default function PieChart({
  width,
  height,
  margin = defaultMargin,
  animate = true,
  pieData,
  ...rest
}: PieProps) {
  const [selectedPieData, setSelectedPieData] = useState<string | null>(null)
  const colorScale = scaleOrdinal({
    domain: pieData.map((d) => d.name),
    range: [
      theme`colors.red.400`,
      theme`colors.purple.400`,
      theme`colors.yellow.400`,
    ],
  })

  if (width < 10) return null

  const innerWidth = width - margin.left - margin.right
  const innerHeight = height - margin.top - margin.bottom
  const radius = Math.min(innerWidth, innerHeight) / 2
  const centerY = innerHeight / 2
  const centerX = innerWidth / 2

  return (
    <div
      style={{
        width,
      }}
      {...rest}
    >
      <svg width={innerWidth} height={height} tw="mx-auto">
        <rect width={width} height={height} fill="none" />
        <Group top={centerY + margin.top} left={centerX + margin.left}>
          <Pie
            data={pieData}
            pieValue={getValue}
            pieSortValues={(a, b) => a - b}
            outerRadius={radius}
          >
            {(pie) => (
              <AnimatedPie<PieData>
                {...pie}
                animate={animate}
                getKey={({ data: { size } }) => size.toFixed(0)}
                getColor={({ data: { name } }) => colorScale(name)}
                onHover={(arc) => setSelectedPieData(arc?.data.name ?? null)}
                getOpacity={(arc) =>
                  getOpacity(arc.data.name, selectedPieData as string)
                }
              />
            )}
          </Pie>
        </Group>
      </svg>
      <LegendOrdinal scale={colorScale}>
        {(labels) => (
          <div tw="mt-5 -ml-5 flex justify-center">
            {labels.map((label, i) => (
              <LegendItem
                key={`legend-quantile-${i}`}
                css={[
                  tw`pl-5 transition-opacity ease-in-out opacity-100`,
                  selectedPieData && tw`opacity-20`,
                  label.text === selectedPieData && tw`opacity-100`,
                ]}
              >
                <svg width={14} height={14}>
                  <circle
                    fill={label.value}
                    r={14 / 2}
                    cx={14 / 2}
                    cy={14 / 2}
                  />
                </svg>
                <LegendLabel tw="pl-2 text-xs">
                  {capitalize(label.text)}
                </LegendLabel>
              </LegendItem>
            ))}
          </div>
        )}
      </LegendOrdinal>
      <div tw="mt-1 text-sm text-center">2020</div>
    </div>
  )
}

// react-spring transition definitions
type AnimatedStyles = {
  startAngle: SpringValue
  endAngle: SpringValue
  opacity: SpringValue
}

const fromLeaveTransition = ({ endAngle }: PieArcDatum<any>) => ({
  // enter from 360° if end angle is > 180°
  startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  opacity: 0,
})

const enterUpdateTransition = ({ startAngle, endAngle }: PieArcDatum<any>) => ({
  startAngle,
  endAngle,
  opacity: 1,
})

type AnimatedPieProps<Datum> = ProvidedProps<Datum> & {
  animate?: boolean
  getKey: (d: PieArcDatum<Datum>) => string
  getColor: (d: PieArcDatum<Datum>) => string
  onHover: (d: PieArcDatum<Datum> | null) => void
  getOpacity: (d: PieArcDatum<Datum>) => number
  delay?: number
}

function AnimatedPie<Datum>({
  animate,
  arcs,
  path,
  getKey,
  getColor,
  onHover,
  getOpacity,
}: AnimatedPieProps<Datum>) {
  const transition = useTransition<PieArcDatum<Datum>, AnimatedStyles>(arcs, {
    from: animate ? fromLeaveTransition : enterUpdateTransition,
    enter: enterUpdateTransition,
    leave: animate ? fromLeaveTransition : enterUpdateTransition,
    keys: getKey,
  })

  const fragment = transition(
    (style: AnimatedStyles, item: PieArcDatum<Datum>, { key }) => {
      const arc = item
      const [centroidX, centroidY] = path.centroid(arc)
      const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.1

      return (
        <g
          key={key}
          css={[
            tw`transition-opacity ease-in-out`,
            getOpacity(arc) === 1 ? tw`opacity-100` : tw`opacity-70`,
          ]}
        >
          <animated.path
            // compute interpolated path d attribute from intermediate angle values
            d={to([style.startAngle, style.endAngle], (startAngle, endAngle) =>
              path({
                ...arc,
                startAngle,
                endAngle,
              })
            )}
            stroke={theme`colors.white`}
            strokeWidth={2}
            fill={getColor(arc)}
            onPointerEnter={() => onHover(arc)}
            onPointerLeave={() => onHover(null)}
          />
          {hasSpaceForLabel && (
            <animated.g style={{ opacity: style.opacity }}>
              <text
                x={centroidX}
                y={centroidY}
                dy=".33em"
                textAnchor="middle"
                pointerEvents="none"
                stroke={theme`colors.grey.1`}
                strokeWidth={2.5}
                tw="text-sm text-white font-bold  fill-current"
              >
                {getKey(arc)}
              </text>
              <text
                x={centroidX}
                y={centroidY}
                dy=".33em"
                textAnchor="middle"
                pointerEvents="none"
                tw="text-sm text-white font-bold fill-current"
              >
                {getKey(arc)}
              </text>
            </animated.g>
          )}
        </g>
      )
    }
  )

  return <>{fragment}</>
}

function getOpacity(name: string, selectedPie: string) {
  if (selectedPie) {
    return selectedPie === name ? 1 : 0.2
  }
  return 1
}
