import React from 'react'
import { Link } from 'gatsby'
import tw from 'twin.macro'

function Container({ link, children }) {
  return link ? (
    <Link
      to={link}
      className="group"
      tw="block focus:outline-none focus-visible:(outline-none ring-2 ring-grey-3 ring-inset border-transparent) rounded"
    >
      {children}
    </Link>
  ) : (
    <div className="group" tw="cursor-not-allowed">
      {children}
    </div>
  )
}

export default function OverviewLinkCard({ children, title, subtitle, link }) {
  return (
    <Container link={link}>
      <div css={[tw`h-20 px-4 pt-4 pb-5 flex justify-between items-center`]}>
        <div tw="flex items-center">
          <div tw="text-blue transition-colors duration-200 ease-in-out group-hover:text-blue-400 group-focus-visible:text-blue-400">
            {children}
          </div>
          <div tw="ml-4 flex flex-col ">
            <span tw="text-grey-1">{title}</span>
            {subtitle && <span tw="text-xs text-grey-7">{subtitle}</span>}
          </div>
        </div>
      </div>
    </Container>
  )
}
