import 'twin.macro'

import { dataToCSV } from '../data/'
import useData from '../data/useData'
import MdOutlineFileDownload from '../images/svg/MdOutlineFileDownload.svg'

export default function DownloadCard({ title, field }) {
  const data = useData()

  const handleDownload = () => {
    const content = dataToCSV(data, field)
    const blob = new Blob([content], { type: 'text/csv' })
    const objectURL = URL.createObjectURL(blob)

    const anchor = document.createElement('a')
    anchor.download = `${title.replace(/\s+/g, '_').toLowerCase()}.csv`
    anchor.href = objectURL
    anchor.click()
    URL.revokeObjectURL(objectURL)
  }

  return (
    <button
      onClick={handleDownload}
      className="group"
      tw="w-full h-20 px-4 pt-4 pb-5 flex justify-between items-center rounded  focus:outline-none focus-visible:(outline-none ring-2 ring-grey-3 ring-inset border-transparent)"
    >
      <div tw="flex items-center">
        <div tw="w-10 h-10 rounded-md p-2 bg-deep-green text-white transition-colors duration-200 ease-in-out group-hover:bg-deep-green-400 group-focus-visible:bg-deep-green-400">
          <MdOutlineFileDownload tw="w-full h-full" />
        </div>
        <div tw="ml-4 text-left">
          <div tw="text-grey-1">{title}</div>
          <div tw="text-xs text-grey-7">Download data as CSV</div>
        </div>
      </div>
    </button>
  )
}
