import React from 'react'

import Layout from '../../components/Layout'
import Air from '../../components/Air'

export default function AirPage() {
  return (
    <Layout>
      <Air />
    </Layout>
  )
}
