import React from 'react'
import 'twin.macro'

export default function OverviewHeader({ title, ...rest }) {
  return (
    <div
      tw="h-14 px-4 flex items-center bg-grey-9 text-sm font-bold  tracking-wide text-grey-1"
      {...rest}
    >
      <div>{title}</div>
    </div>
  )
}
