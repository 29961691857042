import React from 'react'
import 'twin.macro'

export default function OverviewChartTitle({ height, unit, ...rest }) {
  return (
    <text
      x={height / -2}
      y="-50"
      dominantBaseline="start"
      textAnchor="middle"
      transform="rotate(-90)"
      tw="fill-current text-grey-1 tracking-wide"
      {...rest}
    >
      {unit}
    </text>
  )
}
