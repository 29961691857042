import React from 'react'
import { max } from 'd3-array'
import { GridColumns } from '@visx/grid'
import { Group } from '@visx/group'
import { scaleLinear, scaleBand, scaleOrdinal } from '@visx/scale'
import { Bar } from '@visx/shape'
import 'twin.macro'

import OverviewAxes from '../OverviewAxes'

const getX = (d) => d.size
const getY = (d) => d.name

const margin = {
  top: 0,
  left: 100,
  right: 30,
  bottom: 30,
}

const colorScale = scaleOrdinal({
  range: [
    'rgba(171, 99, 250, 0.8)',
    'rgba(255, 161, 90, 0.8)',
    'rgba(25, 211, 243, 0.8)',
    'rgba(255, 102, 146, 0.8)',
    'rgba(182, 232, 128, 0.8)',
    'rgba(255, 151, 255, 0.8)',
    'rgba(254, 203, 82, 0.8)',
    'rgba(99, 110, 250, 0.8)',
    'rgba(0, 204, 150, 0.8)',
    'rgba(239, 85, 59, 0.8)',
  ],
})

export default function DestinationsChart({ width, height, data, ...rest }) {
  const xMax = width - margin.left - margin.right
  const yMax = height - margin.top - margin.bottom

  // scales
  const xScale = scaleLinear({
    domain: [0, max(data, getX)],
    range: [0, xMax],
  })

  const yScale = scaleBand({
    domain: data.map(getY),
    range: [0, yMax],
    padding: 0.4,
  })

  return (
    <div
      style={{
        width,
      }}
      {...rest}
    >
      <svg tw="inline-block" width={width} height={height}>
        <rect width={width} height={height} fill="none" rx={14} ry={14} />
        <Group top={margin.top} left={margin.left}>
          <GridColumns
            scale={xScale}
            width={xMax}
            height={yMax}
            tw="fill-current text-grey-5"
          />

          {data.map((d, i) => {
            const barWidth = xScale(getX(d))
            const barHeight = 11
            const barX = 0
            const barY = yScale(getY(d))

            return (
              <Bar
                key={`bar-${i}`}
                x={barX}
                y={barY}
                width={barWidth}
                height={barHeight}
                fill={colorScale(getY(d))}
              />
            )
          })}

          <OverviewAxes
            xScale={xScale}
            yScale={yScale}
            top={yMax}
            leftTickFormat={(d) => (d.length > 12 ? `${d.slice(0, 13)}...` : d)}
          />
        </Group>
      </svg>
      <div tw="mt-2 flex flex-col justify-center items-center">
        <span tw="text-xs">Flight Legs</span>
        <span tw="mt-1 text-sm">2020</span>
      </div>
    </div>
  )
}
