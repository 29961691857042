import React from 'react'
import { useMeasure } from 'react-use'
import tw, { theme } from 'twin.macro'

import OverviewHeader from '../OverviewHeader'
import CockpitList from '../CockpitList'
import OverviewLinkCard from '../OverviewLinkCard'
import TrendChart from './TrendChart'
import DestinationsChart from './DestinationsChart'
import PieChart from '../PieChart'
import DownloadCard from '../DownloadCard'
import {
  co2Indicator,
  flightCountIndicator,
} from '../../connectors/indicatorTripModeContext/indicators'
import { getTrendData, getTopTenDestinationsData, getPieData } from './utils'

import Sunburst from '../../images/svg/sunburst.svg'
import Sankey from '../../images/svg/sankey.svg'
import TimeSeries from '../../images/svg/time_series.svg'
import BarChart from '../../images/svg/bar_chart.svg'
import Maps from '../../images/svg/maps.svg'
import useData, { useHelperData } from '../../data/useData'

export default function Air() {
  const { flightLegs, airports } = useData()
  const { orgFteByYear, yearlyFte } = useHelperData()
  const [c1Ref, { width: c1Width, height: c1Height }] = useMeasure()
  const [c2Ref, { width: c2Width }] = useMeasure()

  return (
    <div tw="flex-1 lg:grid lg:grid-cols-4 lg:overflow-x-auto">
      <CockpitList parentStyles={[tw`col-span-2`]} tw="overflow-x-hidden">
        <div
          css={[
            `height: calc((100vh - ${theme`spacing.20`}) / 2);`,
            tw`flex flex-col`,
          ]}
        >
          <OverviewHeader
            title={
              <>
                CO<sub>2</sub>e emissions from travel by air (kg CO₂e/FTE p.a.)
              </>
            }
          />
          <div tw="flex-1 h-0" ref={c1Ref}>
            {c1Height && (
              <TrendChart
                width={c1Width}
                height={c1Height - 50}
                data={getTrendData(yearlyFte, flightLegs)}
                unit={'kg CO₂e/FTE p.a.'}
                tw="px-3 py-2"
              />
            )}
          </div>
        </div>
        <div>
          <OverviewHeader title="TOP TEN DESTINATIONS" />
          {c1Height && (
            <DestinationsChart
              width={c1Width}
              height={c1Height - 70}
              data={getTopTenDestinationsData(
                yearlyFte,
                orgFteByYear,
                airports,
                flightLegs
              )}
              tw="px-3 py-2"
            />
          )}
        </div>
      </CockpitList>

      <CockpitList>
        <div
          css={[
            `height: calc((100vh - ${theme`spacing.20`}) / 2);`,
            tw`flex flex-col`,
          ]}
          ref={c2Ref}
        >
          <OverviewHeader title="NUMBER OF FLIGHTS" />
          <div tw="flex-1 h-0" ref={c2Ref}>
            {c1Height && (
              <PieChart
                width={c2Width}
                height={c1Height - 100}
                pieData={getPieData(
                  yearlyFte,
                  orgFteByYear,
                  flightLegs,
                  flightCountIndicator
                )}
                tw="pt-4 pb-3"
              />
            )}
          </div>
        </div>
        <div>
          <OverviewHeader
            title={
              <>
                CO<sub>2</sub>e (t)
              </>
            }
          />
          {c1Height && (
            <PieChart
              width={c2Width}
              height={c1Height - 100}
              pieData={getPieData(
                yearlyFte,
                orgFteByYear,
                flightLegs,
                co2Indicator
              )}
              tw="pt-4 pb-3"
            />
          )}
        </div>
      </CockpitList>

      <CockpitList tw="bg-grey-6">
        <OverviewHeader title="EXPLORE" tw="sticky top-0" />
        <OverviewLinkCard
          title="Sunburst"
          subtitle="Explore hierarchical structures in the data"
          link="/air/sunburst"
        >
          <Sunburst tw="w-10 h-10 rounded-md fill-current" />
        </OverviewLinkCard>

        <OverviewLinkCard
          title="Sankey"
          subtitle="Explore flow between data groups"
          link="/air/sankey"
        >
          <Sankey tw="w-10 h-10 rounded-md fill-current" />
        </OverviewLinkCard>

        <OverviewLinkCard
          title="Time Series"
          subtitle="Explore trends over time"
          link="/air/time-series"
        >
          <TimeSeries tw="w-10 h-10 rounded-md fill-current" />
        </OverviewLinkCard>

        <OverviewLinkCard
          title="Bar Chart"
          subtitle="Generate figures for reports"
          link="/air/bar-chart"
        >
          <BarChart tw="w-10 h-10 rounded-md fill-current" />
        </OverviewLinkCard>

        <OverviewLinkCard title="Maps" subtitle="Explore data by geography">
          <Maps tw="w-10 h-10 rounded-md fill-current text-grey-4" />
        </OverviewLinkCard>

        <DownloadCard field="flight_legs" title="Flight Legs" />
        <DownloadCard field="org_fte" title="Department FTE" />
      </CockpitList>
    </div>
  )
}
